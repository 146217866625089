<template>
    <div>
        <div class="page-header justify-content-between">
            <lable class="page-title">Create New Document</lable> 
            <div class="create_actions">
              <button>CANCEL</button>
              <button>SAVE</button>
          </div>
        </div>
        <div class="create_sec">
          <div class="create_cnt">
              <div class="create_cnt_list">
                <h6>Document Details -</h6>
                <ul>
                  <li>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Category *</label>
                      <multiselect
                            v-model="categorySelected" 
                            :show-labels="false" 
                            :options="categoryOptions">
                        </multiselect>
                    </div>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Sub - Category *</label>
                      <multiselect
                            v-model="SubCategorySelected" 
                            :show-labels="false" 
                            :options="SubCategorygOptions">
                        </multiselect>
                    </div>
                  <li>
                   <div class="d-flex align-items-center">
                      <label class="form_label">Access Type *</label> 
                        <multiselect
                            v-model="prioritySelected" 
                            :show-labels="false" 
                            :options="priorityOptions">
                        </multiselect>
                    </div> 
                  </li>
                  <li class="recurrence">
                    <div class="d-flex align-items-center">
                      <label class="form_label">Subject *</label>
                      <input type="text" class="form_control subject">
                    </div>
                  </li> 
                   <li>
                    <div class="d-flex">
                      <label class="form_label">Description</label>
                      <textarea class="form_control text-area" rows="5" placeholder="Enter event details"></textarea>
                    </div> 
                  </li>
                  <li>
                    <div class="d-flex">
                      <div class="upload-btn-wrapper">
                        <button class="upload_btn">Add attachments</button>
                          <input type="file" name="myfile" />
                      </div>
                    </div>
                  </li> 
                </ul>
              </div>
          </div> 
          <div class="create_cnt">
            <div class="create_cnt_list assign_task_sec">
                <h6>Share with Team Members -</h6>
                <ul>
                  <li>
                    <div class="d-flex">
                      <label class="form_label">Add Members*</label>
                      <div class="people_sec task_people_sec">
                        <input type="text" class="people_search" placeholder="Enter Name" />
                        <div class="d-flex">
                          <div class="people_list">
                          <h5>Add Team Members</h5>
                          <ul>
                            <li>
                              <figure class="dp">
                                <span><img src="@/assets/images/pooja.svg"></span>
                                <figcaption>Pooja H</figcaption>
                              </figure>
                              <em><img src="@/assets/images/plus.svg"></em>
                            </li>
                            <li>
                              <figure class="dp">
                                <span><img src="@/assets/images/pooja.svg"></span>
                                <figcaption>Purnima Ramesh</figcaption>
                              </figure>
                              <em><img src="@/assets/images/plus.svg"></em>
                            </li>
                            <li>
                              <figure class="dp">
                                <span><img src="@/assets/images/pooja.svg"></span>
                                <figcaption>Piyush Shah</figcaption>
                              </figure>
                              <em><img src="@/assets/images/plus.svg"></em>
                            </li>
                            <li>
                              <figure class="dp">
                                <span><img src="@/assets/images/pooja.svg"></span>
                                <figcaption>Prabhas Rao</figcaption>
                              </figure>
                              <em><img src="@/assets/images/plus.svg"></em>
                            </li>
                          </ul>
                        </div>
                        <div class="people_list">
                          <h5>Added Team Members</h5>
                          <ul>
                            <li>
                              <figure class="dp">
                                <span><img src="@/assets/images/pooja.svg"></span>
                                <figcaption>Pooja H</figcaption>
                              </figure>
                              <em><img src="@/assets/images/minus.svg"></em>
                            </li>
                             
                          </ul>
                        </div>
                        </div>  
                      </div>
                    </div>
                  </li>
                </ul>
            </div>
          </div>
          
        </div>
        <div class="create_actions">
              <button>CANCEL</button>
              <button>SAVE</button>
          </div>
    </div>
</template>

<script> 
import Multiselect from "vue-multiselect-inv"; 
  export default {
     components: {
        Multiselect,  
    },
    data() {
      return { 
         
        prioritySelected:'High',
        priorityOptions: ['High ', 'Low', 'Medium'], 
          
        SubCategorySelected: 'Internal meeting',   
        SubCategorygOptions: ['Internal meeting ', 'External meeting',], 
        categorySelected: 'My Visit',   
        categoryOptions: ['My Visit ', 'Client Visit'], 
      }
    },
 
  }
</script>